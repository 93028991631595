import React from "react";
import Image from "react-bootstrap/Image";
import BASISURL from "../constants/basisurl.js";

class About extends React.Component {
	render() {
		return (
			<div>
				<code className="component_info">About.js</code>
				<h1>Über mich</h1>
				<hr />
				<p className="lead">
					Ich arbeite ausschliesslich mit qualitativ hochstehenden
					Gelen.
				</p>
				<hr />
				<Image src={BASISURL + "/img/IMG_2627_klein.jpg"} fluid />
				<br />
				<br />
				<p>
					Heute finden schöne Nägel immer mehr Begeisterung, nicht nur
					in der gehobenen Gesellschaft, sondern auch bei jeder Frau.
					Gepflegte schöne Nägel verändern das Erscheinungsbild einer
					Frau um ein Wesentliches und können besondere Aesthetik
					schaffen.
				</p>

				<p>
					In den letzten Jahren hat sich ein Trend entwickelt, dass es
					nicht nur unglaublich viele Studios gibt, welche ihre
					Dienste anbieten. Dabei sind nicht nur enorme Unterschiede
					im Preis, sondern auch in der Leistung, Qualität und Wissen
					festzustellen. Weiterbildungen und Workshops gehören zu
					meiner Berufsstrategie. Es ist mir ein Anliegen, meinen
					Kunden in allen Bereichen gerecht zu bleiben.
				</p>
			</div>
		);
	}
}

export default About;
