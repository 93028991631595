import * as React from "react";
import { useState } from "react";

const InputField = (props) => {
	const [validation, setValidation] = useState("");

	const onChange = (event) => {
		validate(event.target.value);
		props.setValue(event.target.value);
	};

	const onBlur = () => {
		validate();
	};

	const validate = (input) => {
		const value = input ? input : props.value;
		if (props.isValid(value)) {
			setValidation("is-valid");
		} else {
			setValidation("is-invalid");
		}
	};

	return (
		<>
			<div className="form-group">
				<label>{props.label}</label>
				<input
					onKeyDown={props.onKeyDown}
					className={"form-control " + validation}
					onBlur={onBlur}
					onChange={onChange}
					placeholder={props.placeholder}
					type={props.type}
					value={props.value}
				/>
				{props.invalidMessage && (
					<div className="invalid-feedback">
						{props.invalidMessage}
					</div>
				)}
			</div>
		</>
	);
};

export default InputField;
