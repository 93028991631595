import "isomorphic-fetch";
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import React from "react";
import Row from "react-bootstrap/Row";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Error from "./components/Error.js";
import About from "./components/About.js";
import Contact from "./components/Contact.js";
import ContactGood from "./components/ContactGood.js";
import ContactBad from "./components/ContactBad.js";
import Fuss from "./components/Fuss.js";
import Gallery from "./components/Gallery.js";
import Home from "./components/Home.js";
import Navigation from "./components/Navigation.js";
import OffersNails from "./components/OffersNails.js";
import OffersFeet from "./components/OffersFeet.js";
import Sidebar from "./components/Sidebar.js";
import Impressum from "./components/Impressum.js";

function App() {
	return (
		<>
			<Navigation />
			<Container>
				<Row>
					<Col lg={8} md={7}>
						<Router>
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path="home" element={<Home />} />
								<Route path="about" element={<About />} />
								<Route
									path="impressum"
									element={<Impressum />}
								/>
								<Route path="contact" element={<Contact />} />
								<Route
									path="contactError"
									element={<ContactBad />}
								/>
								<Route
									path="contactEnd"
									element={<ContactGood />}
								/>
								<Route path="feet" element={<OffersFeet />} />
								<Route path="nails" element={<OffersNails />} />
								<Route path="gallery" element={<Gallery />} />
								<Route
									path="impressum"
									element={<Impressum />}
								/>
								<Route element={<Error />} />
							</Routes>
						</Router>
					</Col>
					<Col lg={4} md={5}>
						<Sidebar />
					</Col>
				</Row>
			</Container>
			<Fuss />
		</>
	);
}

export default App;
