import BASISURL from './basisurl.js';

const studio = [
	{
		original: BASISURL + "/img/gallery/studio/1600x900/IMG_3274.jpg",
		thumbnail: BASISURL + "/img/gallery/studio/100x100/IMG_3274.jpg"
	},
	{
		original: BASISURL + "/img/gallery/studio/1600x900/IMG_3262.jpg",
		thumbnail: BASISURL + "/img/gallery/studio/100x100/IMG_3262.jpg"
	},
	{
		original: BASISURL + "/img/gallery/studio/1600x900/IMG_3270.jpg",
		thumbnail: BASISURL + "/img/gallery/studio/100x100/IMG_3270.jpg"
	},
	{
		original: BASISURL + "/img/gallery/studio/1600x900/IMG_2642.jpg",
		thumbnail: BASISURL + "/img/gallery/studio/100x100/IMG_2642.jpg"
	},
	{
		original: BASISURL + "/img/gallery/studio/1600x900/IMG_2683.jpg",
		thumbnail: BASISURL + "/img/gallery/studio/100x100/IMG_2683.jpg"
	},
	{
		original: BASISURL + "/img/gallery/studio/1600x900/IMG_2720.jpg",
		thumbnail: BASISURL + "/img/gallery/studio/100x100/IMG_2720.jpg"
	},
	{
		original: BASISURL + "/img/gallery/studio/1600x900/IMG_2735.jpg",
		thumbnail: BASISURL + "/img/gallery/studio/100x100/IMG_2735.jpg"
	},
	{
		original: BASISURL + "/img/gallery/studio/1600x900/IMG_2742.jpg",
		thumbnail: BASISURL + "/img/gallery/studio/100x100/IMG_2742.jpg"
	},
	{
		original: BASISURL + "/img/gallery/studio/1600x900/IMG_2765.jpg",
		thumbnail: BASISURL + "/img/gallery/studio/100x100/IMG_2765.jpg"
	}
];

export default studio;
