import React from "react";

class Impressum extends React.Component {
	render() {
		return (
  <div>
    <code className="component_info">About.js</code>
    <h1>Impressum und Datenschutz</h1>

    <hr />

    <h3>Kontaktadresse</h3>
    <p>
      <b>Hand & Fuss</b>
      <br />
					Löwenstrasse 28
      <br />
					8280 Kreuzlingen
      <br />
					Tel +41 71 671 16 66
    </p>
    <p>
		<a href="https://www.anitas-nailstudio.com/">anitas-nailstudio.com</a>
    </p>
    <hr />
    <h3>Handelsregistereintrag</h3>
    <p>Eingetragener Firmenname: Hand und Fuss</p>

    <hr />

    <h3>Mehrwertsteuernummer</h3>
    <p>CHE-108.087.XXX</p>

    <hr />

    <h3>Haftungsausschluss</h3>
    <p>
					Der Autor übernimmt keinerlei Gewähr hinsichtlich der
					inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
					Zuverlässigkeit und Vollständigkeit der Informationen.
    </p>

    <p>
					Haftungsansprüche gegen den Autor wegen Schäden materieller
					oder immaterieller Art, welche aus dem Zugriff oder der
					Nutzung bzw. Nichtnutzung der veröffentlichten
					Informationen, durch Missbrauch der Verbindung oder durch
					technische Störungen entstanden sind, werden ausgeschlossen.
    </p>

    <p>
					Alle Angebote sind unverbindlich. Der Autor behält es sich
					ausdrücklich vor, Teile der Seiten oder das gesamte Angebot
					ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu
					löschen oder die Veröffentlichung zeitweise oder endgültig
					einzustellen.
    </p>

    <hr />

    <h3>Haftung für Links</h3>
    <p>
					Verweise und Links auf Webseiten Dritter liegen ausserhalb
					unseres Verantwortungsbereichs Es wird jegliche
					Verantwortung für solche Webseiten abgelehnt. Der Zugriff
					und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr
					des Nutzers oder der Nutzerin.
      {" "}
    </p>

    <hr />

    <h3>Urheberrechte</h3>
    <p>
					Die Urheber- und alle anderen Rechte an Inhalten, Bildern,
					Fotos oder anderen Dateien auf der Website gehören
					ausschliesslich oder den speziell genannten Rechtsinhabern.
					Für die Reproduktion jeglicher Elemente ist die schriftliche
					Zustimmung der Urheberrechtsträger im Voraus einzuholen.
    </p>
  </div>
		);
	}
}

export default Impressum;
