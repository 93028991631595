import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faShoePrints,
	faAmericanSignLanguageInterpreting,
	faHandsHelping
} from "@fortawesome/free-solid-svg-icons";

class Home extends React.Component {
	render() {
		return (
  <div>
    <code className="component_info">Home.js</code>
    <h1>Willkommen</h1>
    <hr />
    <p className="lead">
					Hände und Füsse sind Ihre Visitenkarte.
      <br />
					Herzlich Willkommen, schön, dass Sie mich gefunden haben.
    </p>
    <hr />
    <h2>
      <FontAwesomeIcon icon={faShoePrints} />
      {' '}
&nbsp;Füsse
    </h2>
    <p>
					Gepflegte Füsse prägen nicht nur Ihr Aussehen, sie schmerzen
					auch weniger. Mit einer regelmässigen Fusspflege können Sie
					viel zur Prophylaxe und Gesundheit Ihrer Füsse und Nägeln
					beitragen. Bei Hornhaut ist das Ergebnis schon nach der
					ersten Behandlung zu sehen.
    </p>

    <h3>Wellnessbehandlung</h3>
    <p>
					Zur vollständigen Fusspflege gehört aber auch eine
					"Wellnessbehandlung", sei dies ein Peeling oder eine trendige
					Nagelverzierung. Gönnen Sie sich
					diesen Luxus und gehen Sie wie auf Wolken.
    </p>

    <h2>
      <FontAwesomeIcon
        icon={faAmericanSignLanguageInterpreting}
      />
      {" "}
					&nbsp;Nägel
    </h2>
    <p>
					Bei Problemnägeln braucht es hingegen etwas Geduld und Zeit,
					bis eine Aenderung spürbar und sichtbar wird. Oftmals ist
					schon mit einfachen Mitteln, Geduld und Fachwissen eine
					Besserung möglich. Als diplomierte Fusspflegerin, kann ich
					Ihnen bei vielen Fuss- und Nagelproblemen eine Lösung und
					fast immer eine deutliche Schmerzlinderung ermöglichen.
    </p>

    <h2>
      <FontAwesomeIcon icon={faHandsHelping} />
      {' '}
&nbsp;Hände
    </h2>
    <p>
					Gepflegte Hände. Nicht jeder Mensch hat von Natur aus schöne
					Fingernägel. Brüchige, zu weiche oder deformierte Nägel sind
					oft das Problem vieler Kunden. Eine Naturnagelverstärkung
					kann Ihnen zu gepflegten, natürlichen Nägeln verhelfen. Wer
					es lieber etwas trendiger mag, stehen diverse
					Dekomöglichkeiten zur Auswahl, von schlichten Verzierungen
					bis hin zu kunstvollen Kunstwerken, wie wahlweise mit
					Airbrush.
    </p>

    <hr />

    <p>
					Es wäre mir ein Vergnügen, Sie in meinem gemütlichen Studio,
					in entspannter Atmosphäre pflegen und verwöhnen zu dürfen
					und freue mich auf Ihren Besuch.
    </p>

    <p> Anita Reithinger</p>
  </div>
		);
	}
}

export default Home;
