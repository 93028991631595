import React from "react";
import Image from "react-bootstrap/Image";
import BASISURL from "../constants/basisurl.js";
// import {
// 	faFacebook,
// 	facebook,
// 	faFacebookSquare,
// 	faFacebookF,
// 	faSpinner
// } from "@fortawesome/free-brands-svg-icons";

class Sidebar extends React.Component {
	render() {
		return (
			<div className="sidebar">
				<code className="component_info">Sidebar.js</code>
				<div className="text-center">
					<Image
						src={BASISURL + "/img/logo_gold_square.png"}
						roundedCircle
						fluid
					/>
				</div>
				<hr />
				<h3>Kontakt</h3>
				<p>
					<b>Hand & Fuss</b>
					<br />
					Anita Reithinger
					<br />
					Dipl. Fusspflegerin SFPV
					<br />
					Nagelkosmetikerin
				</p>
				<p>
					Löwenstrasse 28
					<br />
					8280 Kreuzlingen
				</p>
				<p>
					{" "}
					Tel <a href="tel:+41716711666">+41 71 671 16 66</a>
					<br />
					<a href="https://www.anitas-nailstudio.com/">
						anitas-nailstudio.com
					</a>
				</p>

				<hr />
				<h3>Öffnungszeiten</h3>
				<p>
					<b>Montag bis Freitag</b>
					<br />
					08.00 bis 18.30 Uhr
				</p>
				<p>
					<b>Samstags</b>
					<br />
					Nach Absprache
				</p>
				<p>
					<i>
						Am Sonntag und an{" "}
						<a href="https://www.feiertagskalender.ch/index.php?geo=141&jahr=<?php echo date('Y'); ?>&klasse=3">
							Feiertagen
						</a>{" "}
						geschlossen
					</i>
				</p>
				<hr />
				<p>
					<strong>Wichtig:</strong> Konsultationen welche nicht
					eingehalten werden können, sind 24 Stunden vorher abzusagen.
					Sie ersparen sich deren Berechnung.
				</p>
				<hr />
				<p>© Hand und Fuss – 2024</p>
			</div>
		);
	}
}

export default Sidebar;
