import React from "react";

class ContactBad extends React.Component {
	render() {
		return (
			<div>
				<code className="component_info">Contact.js</code>
				<h1>Kontakt</h1>
				<hr />
				<h3>Leider ist etwas schiefgelaufen.</h3>
				<p>
					Unter <a href="tel:+41716711666">+41 71 671 16 66</a> bin
					ich bei Problemen wärend den Öffnungszeiten erreichbar.
				</p>
			</div>
		);
	}
}

export default ContactBad;
