import React from "react";
import ImageGallery from "react-image-gallery";
import studio from '../constants/pictures_studio.js';
import nails from '../constants/pictures_nails.js';
import fails from '../constants/pictures_fails.js';

class Gallery extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modalIsOpen: false };
	}

	toggleModal = () => {
		this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
	};

	render() {
		return (
  <div>
    <code className="component_info">Gallery.js</code>
    <h1>Fotos</h1>
    <hr />
    <h2>Mein Studio</h2>
    <ImageGallery showPlayButton={false} items={studio} />

    <hr />

    <h2>Gelnägel</h2>
    <ImageGallery showPlayButton={false} items={nails} />

    <h2>Fremdstudios: Geiz ist geil :-((</h2>
    <ImageGallery showPlayButton={false} items={fails} />

  </div>
		);
	}
}

export default Gallery;
