import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React from "react";
import BASISURL from "../constants/basisurl.js";
import NavDropdown from "react-bootstrap/NavDropdown";

class Navigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sitename: "nav_image_home"
		};

		this.navbar = false;

		this.navImageCreator = this.navImageCreator.bind(this);
	}

	componentDidMount() {
		this.navImageCreator();
	}

	scrollToTop() {
		  document.body.scrollTop = 0; // For Safari
		  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	} 

	navImageCreator(e) {
		if (e === undefined) {
			e = window.location.href.substring(
				window.location.href.lastIndexOf("/") + 1
			);
			this.setState({ sitename: `nav_image_${e}` });
		} else {
			this.setState({ sitename: `nav_image_${e}` });
		}
		this.scrollToTop()
	}

	render() {
		// https://react-bootstrap.github.io/components/navbar/
		return (
			<>
				<Navbar bg="primary" variant="light" expand="lg" sticky="top">
					<Container>
						<Navbar.Brand href="/#/home">
							<img
								src={BASISURL + "/img/logo_white.png"}
								width="auto"
								height="30"
								className="d-inline-block align-center"
								alt="Hand und Fuss logo"
							/>{" "}
							Hand & Fuss
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="mr-auto">
								<Nav.Link
									onClick={e => this.navImageCreator("home")}
								href="/#/home"
								>
									Willkommen
								</Nav.Link>
								<Nav.Link
									onClick={e => this.navImageCreator("about")}
								href="/#/about"
								>
									Über mich
								</Nav.Link>
								<Nav.Link
									onClick={e =>
										this.navImageCreator("contact")
									}
								href="/#/contact"
								>
									Kontakt
								</Nav.Link>
								<NavDropdown
									title="Leistungen"
									id="basic-nav-dropdown"
								>
									<NavDropdown.Item
									href="/#/nails"
										onClick={e =>
											this.navImageCreator("nails")
										}
									>
										Nägel
									</NavDropdown.Item>

									<NavDropdown.Item
									href="/#/feet"
										onClick={e =>
											this.navImageCreator("feet")
										}
									>
										Füsse
									</NavDropdown.Item>
								</NavDropdown>
								<Nav.Link
									onClick={e =>
										this.navImageCreator("gallery")
									}
								href="/#/gallery"
								>
									Fotos
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
				<div className={`nav_image, ${this.state.sitename}`} />
				<br />
			</>
		);
	}
}

export default Navigation;
