import BASISURL from './basisurl.js';

const naegel = [
	{
		original: BASISURL + "/img/gallery/naegel/1600x900/0001.JPG",
		thumbnail: BASISURL + "/img/gallery/naegel/100x100/0001.JPG"
	},
	{
		original: BASISURL + "/img/gallery/naegel/1600x900/0002.jpg",
		thumbnail: BASISURL + "/img/gallery/naegel/100x100/0002.jpg"
	},
	{
		original: BASISURL + "/img/gallery/naegel/1600x900/0003.jpg",
		thumbnail: BASISURL + "/img/gallery/naegel/100x100/0003.jpg"
	},
	{
		original: BASISURL + "/img/gallery/naegel/1600x900/0004.jpg",
		thumbnail: BASISURL + "/img/gallery/naegel/100x100/0004.jpg"
	},
	{
		original: BASISURL + "/img/gallery/naegel/1600x900/0005.jpg",
		thumbnail: BASISURL + "/img/gallery/naegel/100x100/0005.jpg"
	},
	{
		original: BASISURL + "/img/gallery/naegel/1600x900/0006.jpg",
		thumbnail: BASISURL + "/img/gallery/naegel/100x100/0006.jpg"
	},
	{
		original: BASISURL + "/img/gallery/naegel/1600x900/0007.jpg",
		thumbnail: BASISURL + "/img/gallery/naegel/100x100/0007.jpg"
	},
	{
		original: BASISURL + "/img/gallery/naegel/1600x900/0008.jpg",
		thumbnail: BASISURL + "/img/gallery/naegel/100x100/0008.jpg"
	},
	{
		original: BASISURL + "/img/gallery/naegel/1600x900/0009.jpg",
		thumbnail: BASISURL + "/img/gallery/naegel/100x100/0009.jpg"
	},
	{
		original: BASISURL + "/img/gallery/naegel/1600x900/0010.jpg",
		thumbnail: BASISURL + "/img/gallery/naegel/100x100/0010.jpg"
	},
	{
		original: BASISURL + "/img/gallery/naegel/1600x900/0011.jpg",
		thumbnail: BASISURL + "/img/gallery/naegel/100x100/0011.jpg"
	}
];

export default naegel;
