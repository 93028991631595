import * as React from 'react';

const InputFieldTextArea = (props) => {

	const onChange = (event) => {
		props.setValue(event.target.value);
	};

	return (
		<>
			<div className="form-group">
				<label>{props.label}</label>
				<textarea
					className={'form-control'}
					onChange={onChange}
					placeholder={props.placeholder}
					rows="8"
					value={props.value}
				/>
			</div>
		</>
	);
};

export default InputFieldTextArea;
