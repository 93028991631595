import React from "react";

class ContactGood extends React.Component {
	render() {
		return (
			<div>
				<code className="component_info">Contact.js</code>
				<h1>Kontakt</h1>
				<hr />
				<h3>Vielen Dank für Ihre Nachricht.</h3>
				<p>Ich werde mich so schnell wie möglich um Ihre Nachricht kümmern.</p>
			</div>
		);
	}
}

export default ContactGood;
