import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import BASISURL from '../constants/basisurl.js';

class Error extends Component {
	render() {
		return (
  <Container>
    <code className="component_info">Error.js</code>
    <Row>
      <Col sm={12} className="text-center">
        <h1>404</h1>
        <hr />
        <h3>Diese Seite existiert leider nicht.</h3>
        <br />
        <Image src={BASISURL + "/img/salem.gif"} fluid />
      </Col>
    </Row>
  </Container>
		);
	}
}

export default Error;
