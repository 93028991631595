import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import InputField from "./InputField";
import InputFieldTextArea from "./InputFieldTextArea";
import isEmailValid from "../functions/isEmailValid";

function Contact() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const sendFormNew = () => {
		if (checkAllInputFields()) {
			const templateParams = {
				user_name: name,
				user_email: email,
				message: message,
			};
			setIsLoading(true);
			emailjs
				.send(
					"service_p5djrrj",
					"template_23y819k",
					templateParams,
					"user_1YkWxnIKqu5sDyPZXDVFp"
				)
				.then(
					function (response) {
						console.log("SUCCESS!", response.status, response.text);
						window.location.href = "/#/contactEnd";
					},
					function (err) {
						console.log("FAILED...", err);
						window.location.href = "/#/contactError";
					}
				);
		}
	};

	const checkAllInputFields = () => {
		let returnValue = true;
		if (!validateNames(name)) {
			returnValue = false;
		}
		if (!validateEmail(email)) {
			returnValue = false;
		}
		if (!returnValue) {
			console.error("Nicht alle Felder wurden korrekt ausgefüllt");
		}
		return returnValue;
	};

	const validateNames = (input) => {
		if (input === " ") {
			return false;
		}
		if (input === "") {
			return false;
		}
		return true;
	};

	const validateEmail = (input) => {
		return isEmailValid(input);
	};

	const checkForKeyboardEvent = (event) => {
		//if (event.key === 'Enter') {
		////save();
		//}
	};

	return (
		<div>
			<code className="component_info">Contact.js</code>
			<h1>Kontakt</h1>
			<hr />
			<h3>Kontaktieren Sie uns!</h3>
			<p className="primary-color">
				Termine bitte telefonisch vereinbaren:{" "}
				<a href="tel:+41716711666">+41 71 671 16 66</a>
			</p>

			<p>
				Sie interessieren sich für unsere Leistungen? Kontaktieren Sie
				uns über das Formular unten und wir melden uns so bald wie
				möglich bei Ihnen!
			</p>

			<div className="contact-form">
				<InputField
					value={name}
					setValue={setName}
					isValid={validateNames}
					label="Name"
					placeholder={"Name"}
					type={"text"}
					onKeyDown={checkForKeyboardEvent}
					invalidMessage={"Geben Sie einen Namen ein."}
				/>
				<InputField
					value={email}
					setValue={setEmail}
					isValid={validateEmail}
					label="E-Mail-Adresse"
					placeholder={"E-Mail"}
					type={"email"}
					onKeyDown={checkForKeyboardEvent}
					invalidMessage={
						"Geben Sie eine gültige E-Mail-Adresse ein."
					}
				/>
				<InputFieldTextArea
					value={message}
					setValue={setMessage}
					label="Nachricht"
					placeholder={"Text"}
				/>
				<Button
					variant="outline-primary"
					value="Send"
					type="submit"
					onClick={sendFormNew}
				>
					{isLoading && (
						<FontAwesomeIcon className="fa-spin" icon={faSync} />
					)}{" "}
					Abschicken
				</Button>
			</div>
		</div>
	);
}

export default Contact;
