import React from "react";
import BASISURL from "../constants/basisurl.js";

class OffersNails extends React.Component {
	constructor(props) {
		super(props);

		this.pic1 = BASISURL + "/img/sonstiges/zahnbleichen.jpg";
		this.pic2 = BASISURL + "/img/sonstiges/zahnfarbe.jpg";
	}

	render() {
		let prices = [
			{ name: "Neumodellage", price: "105.00 CHF" },
			{ name: "Reparatur nach Aufwand", price: "ab 7.00 CHF" },
			{ name: "Auffüllen mit French", price: "83.00 CHF" },
			{ name: " Auffüllen ohne Farbe ", price: "73.00 CHF" },
			{ name: "Naildeko", price: "1 Nagel 1.50 CHF" },
			{ name: "Naildeko nach Aufwand", price: "10 Nägel ab 10.00 CHF" },
			{ name: "Maniküre", price: "50.00 CHF" },
			{ name: "Maniküre mit Gellack", price: "75.00 CHF" },
			{ name: "Nägel lackieren", price: "10.00 CHF" },
		];

		return (
			<div>
				<code className="component_info">Offers.js</code>
				<h1>Nägel</h1>
				<hr />

				<h2>Preise Nägel</h2>
				<table className="table table-bordered table-hover align-middle">
					<thead>
						<tr>
							<th>Leistung</th>
							<th>
								<div className="float-end">Preis</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{prices.map((p, i) => (
							<tr key={i}>
								<td>{p.name}</td>
								<td>
									<div className="float-end">{p.price}</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

export default OffersNails;
