import React from "react";
import Image from "react-bootstrap/Image";
import BASISURL from "../constants/basisurl.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class OffersFeet extends React.Component {
	constructor(props) {
		super(props);

		this.pic1 = BASISURL + "/img/sonstiges/eingewachsene_naegel.jpg";
	}

	render() {
		let prices = [
			{ name: "Grosse (med.) Pedicure mit Gel", price: "93.00 CHF" },
			{ name: "Grosse (med.) Pedicure", price: "79.00 CHF" },
			{ name: "Kleine kosmetische Fusspflege", price: "65.00 CHF" },
			{
				name: "Kleine kosmetische Fusspflege mit Gel",
				price: "85.00 CHF",
			},
			{ name: "Nägel lackieren", price: "10.00 CHF" },
			{ name: "Nägel lackieren mit eigenem Lack", price: "8.00 CHF" },
			{ name: "Calluspeeling", price: "40.00 CHF" },
			{ name: "Nagelprothese", price: "ab 20.00 CHF" },
			{
				name: "Bei starker Hornhaut und grossen Hühneraugen",
				price: "ab 15.00 CHF",
			},
			{ name: "Eingewachsene Nägel (nach Aufwand)", price: "ab 15.00 CHF" },
		];

		return (
			<div>
				<code className="component_info">Offers.js</code>
				<h1>Füsse</h1>
				<hr />

				<h2>Preise Füsse</h2>

				<table className="table table-bordered table-hover align-middle">
					<thead>
						<tr>
							<th>Leistung</th>
							<th>
								<div className="float-end">Preis</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{prices.map((p, i) => (
							<tr key={i}>
								<td>{p.name}</td>
								<td>
									<div className="float-end">{p.price}</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>

				<div className="alert alert-secondary" style={{backgroundColor: '#e2e3e5'}}>
					Preise werden bei Mehraufwand angepasst.
				</div>

				<div className="alert alert-secondary" style={{backgroundColor: '#e2e3e5'}}>
					Bezahlen mit Twint: +1.8%
				</div>

				<div className="zahncontainer">
					<h2>Zu meinen Behandlungen gehören auch:</h2>
					<hr />
					<Row>
						<Col sm={12} lg={6}>
							<Image
								src={this.pic1}
								className="zahnpicture"
								fluid
							/>
						</Col>
						<Col sm={12} lg={6}>
							<ul>
								<li>Roll- und eingewachsene Nägel</li>
								<li>Hühneraugen</li>
								<li>
									Pflegen von pilzbefallenen Nägeln während
									der Behandlung mit Medikamenten oder spez.
									Lack
								</li>
								<li>verdickte Nagelplatten</li>
								<li>
									Entfernen der besonders dicken Hornhaut mit
									dem Skalpell
								</li>
							</ul>
						</Col>
					</Row>
				</div>

				<h2 id="calluspeeling">Calluspeeling</h2>
				<p>Erstaunliche Ergebnisse schon nach der ersten Behandlung</p>

				<div className="embed-responsive embed-responsive-16by9">
					<iframe
						title="video"
						width="560"
						height="315"
						src="https://www.youtube.com/embed/Yjep42aJ68M"
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
			</div>
		);
	}
}

export default OffersFeet;
