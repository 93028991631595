import BASISURL from "./basisurl.js";

const fails = [
	{
		original: BASISURL + "/img/gallery/fails/1600x900/01.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/01.jpg"
	},
	{
		original: BASISURL + "/img/gallery/fails/1600x900/02.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/02.jpg"
	},
	{
		original: BASISURL + "/img/gallery/fails/1600x900/03.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/03.jpg"
	},
	{
		original: BASISURL + "/img/gallery/fails/1600x900/04.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/04.jpg"
	},
	{
		original: BASISURL + "/img/gallery/fails/1600x900/05.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/05.jpg"
	},
	{
		original: BASISURL + "/img/gallery/fails/1600x900/06.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/06.jpg"
	},
	{
		original: BASISURL + "/img/gallery/fails/1600x900/07.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/07.jpg"
	},
	{
		original: BASISURL + "/img/gallery/fails/1600x900/08.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/08.jpg"
	},
	{
		original: BASISURL + "/img/gallery/fails/1600x900/09.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/09.jpg"
	},
	{
		original: BASISURL + "/img/gallery/fails/1600x900/10.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/10.jpg"
	},
	{
		original: BASISURL + "/img/gallery/fails/1600x900/11.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/11.jpg"
	},
	{
		original: BASISURL + "/img/gallery/fails/1600x900/12.jpg",
		thumbnail: BASISURL + "/img/gallery/fails/100x100/12.jpg"
	}
];

export default fails;
