import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Fuss extends Component {
	scrollTop() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	render() {
		// console.log(this.props.match.url);
		return (
  <>
    <div className="footer">
      <code className="component_info">Fuss.js</code>
      <Container>
        <Row>
          <Col sm={12} className="text-center">
            <a href="#/home" onClick={this.scrollTop}>
									© 2024 | Hand & Fuss
            </a>
            <br />
          </Col>
        </Row>
      </Container>
    </div>
  </>
		);
	}
}

export default Fuss;
